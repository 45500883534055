
import {defineComponent, ref, watch} from "vue"
import ReportService from "@/services/ReportService"
import OrganizationService from "@/services/OrganizationService"
import { Organization } from "@/models/Organization"

export default defineComponent({
  name: "TemplateImport",
  emits: ["template-added"],
  props: {
    selectedUser: {
      type: Object,
      default: null,
    },
    organization: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const languages = ["EN", "NL", "FR", "DE"]
    const defaultReportOrganizationId = process.env.VUE_APP_DEFAULT_REPORT_ORGANIZATION_ID;
    const templateLocale = ref(props.language)
    const searchValue = ref("")
    const selectedTemplates = ref()
    const templates = ref()
    const reportService = new ReportService()
    const organizationService = OrganizationService.getInstance()
    const selectedOrganization = ref<Organization>(null)
    const organizations = ref()

    watch(
      () => templateLocale.value,
      () => {
        loadTemplatesFromPrototype()
      }
    )

    watch(
      () => searchValue.value,
      () => {
        loadTemplatesFromPrototype()
      }
    )

    watch(
      () => selectedOrganization.value,
      () => {
        loadTemplatesFromPrototype()
      }
    )

    loadOrganizations()

    async function loadOrganizations() {
      const response = await organizationService.getOrganizations(0, "", "");
      if (response.rows?.length) {
        organizations.value= response.rows
        selectedOrganization.value = organizations.value.find((org : Organization) => org["id"] === defaultReportOrganizationId);
      }

    }

    function loadTemplatesFromPrototype() {
      if (selectedOrganization && selectedOrganization.value && selectedOrganization.value.id) {
        reportService
          .getTemplates(templateLocale.value as string, selectedOrganization.value.id)
          .then((result) => {
            templates.value = result.data
          })
      }
    }

    function importTemplates() {
      if (selectedTemplates.value != null) {
        if (props.selectedUser !== null) {
        } else if (props.organization) {
          let templatesIds = []
          for (let template of selectedTemplates.value) {
            templatesIds.push(template.id)
          }
          reportService
            .copyTemplatesFromOrganization(props.organization + "", templatesIds)
            .then(() => {
              emit("template-added")
            })
        }
      }
    }

    return {
      languages,
      templateLocale,
      searchValue,
      selectedTemplates,
      templates,
      importTemplates,
      organizations,
      selectedOrganization,
    }
  },
})
