import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52763c0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "flex align-items-center w-full justify-content-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "mt-2 mr-8 alias" }
const _hoisted_5 = {
  key: 0,
  class: "mt-2 mr-6 supportAlias"
}
const _hoisted_6 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_LinkLabel = _resolveComponent("LinkLabel")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TemplateEditModal = _resolveComponent("TemplateEditModal")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmPopup),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.template.alias), 1),
            (_ctx.template.internalAlias)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_5, [
                  _createVNode(_component_mdicon, {
                    class: "mr-2",
                    name: "face-agent"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.template.internalAlias), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.template.user)
            ? (_openBlock(), _createBlock(_component_LinkLabel, {
                key: 0,
                class: "linklabel-user",
                value: _ctx.template.user.username,
                type: "user",
                id: _ctx.template.user.id
              }, null, 8, ["value", "id"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            class: "mr-4 ml-2 p-button-rounded p-button-text",
            onClick: _cache[0] || (_cache[0] = ($event: any) => {;(_ctx.showEdit = true), (_ctx.templateRef = _ctx.template)})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_mdicon, {
                name: "pencil",
                size: 30
              })
            ]),
            _: 1
          }),
          _createVNode(_component_Button, {
            class: "p-button-rounded p-button-text p-button-danger",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteTemplate($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_mdicon, {
                name: "delete",
                size: 30
              })
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", null, [
        (_ctx.showEdit)
          ? (_openBlock(), _createBlock(_component_Dialog, {
              key: 0,
              header: "Edit template",
              modal: true,
              visible: _ctx.showEdit,
              "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showEdit) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TemplateEditModal, {
                  class: "modal",
                  organization: _ctx.organization,
                  template: _ctx.template,
                  onTemplateChanged: _ctx.templateChanged
                }, null, 8, ["organization", "template", "onTemplateChanged"])
              ]),
              _: 1
            }, 8, ["visible"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}