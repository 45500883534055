
import { computed, ref, watch, defineComponent } from "vue"
import { useRoute } from "vue-router"
import OrganizationService from "@/services/OrganizationService"
import ReportService from "@/services/ReportService"
import TemplateItem from "@/components/reports/TemplateItem.vue"
import TemplateAddModal from "@/components/reports/tools/TemplateAddModal.vue"
import TemplateImport from "@/components/reports/tools/TemplateImport.vue"
import SecondaryButton from "../buttons/SecondaryButton.vue"

export default defineComponent({
  name: "TemplatesList",
  components: { TemplateImport, TemplateAddModal, TemplateItem, SecondaryButton },
  props: {
    organization: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const templateLocale = ref("EN")
    const languages = ["EN", "NL", "FR", "DE"]
    const route = useRoute()
    const organizationService = OrganizationService.getInstance()
    const organizationUsers = ref([])
    const selectedUser = ref(null)
    const reportService = new ReportService()
    const organizationId = computed(() => route.params.organization_id as string)
    const showAdd = ref(false)
    const showImport = ref(false)
    const templates = ref([])

    watch(
      () => templateLocale.value,
      () => {
        resetDropdown()
        loadTemplates()
      }
    )

    watch(
      () => selectedUser.value,
      () => {
        if (selectedUser.value == null) {
          loadTemplates()
        } else {
          loadTemplatesForUser()
        }
      }
    )

    function loadTemplatesForUser() {
      if (organizationId) {
        reportService
          .getTemplateForUserFromOrganization(
            templateLocale.value,
            organizationId.value,
            selectedUser.value.id
          )
          .then((result) => {
            templates.value = result.data
            showAdd.value = false
          })
      }
    }

    loadTemplates()

    function loadTemplates() {
      if (organizationId) {
        reportService.getTemplates(templateLocale.value, organizationId.value).then((result) => {
          templates.value = result.data
          showAdd.value = false
          showImport.value = false
        })
      }
    }

    loadOrganizationUsers()

    function loadOrganizationUsers() {
      if (organizationId) {
        organizationService.getUsersForOrganization(organizationId.value).then((result) => {
          let rows = result.rows
          for (let row of rows) {
            organizationUsers.value.push(row.user)
          }
        })
      }
    }

    function resetDropdown() {
      selectedUser.value = null
    }

    return {
      templateLocale,
      languages,
      organizationId,
      organizationUsers,
      showAdd,
      showImport,
      selectedUser,
      loadTemplates,
      templates,
      resetDropdown,
    }
  },
})
