<template>
  <div>
    <form
      class="flex flex-column w-full"
      @click.prevent=""
    >
      <div class="flex align-items-center justify-content-between mb-4">
        <!--        <SelectButton :options="languages" v-model="templateLocale" />-->
        <PrimaryButton
          :label="'Add'"
          @click.prevent="addTemplate"
        />
      </div>
      <div class="flex align-items-center justify-content-start flex-wrap mb-4">
        <div class="flex flex-column mr-4">
          <label for="alias">Alias</label>
          <InputText
            id="alias"
            v-model="v$.alias.$model"
          />
          <label class="mt-2">Support alias</label>
          <InputText v-model="v$.internalAlias.$model" />
        </div>
        <div class="flex flex-column justify-content-end flex-1">
          <div class="flex flex-column w-full">
            <label for="title">Title</label>
            <InputText
              id="title"
              v-model="v$.title.$model"
              class=""
            />
          </div>
          <div class="flex justify-content-between align-items-center mt-4">
            <LinkLabel
              v-if="selectedUser"
              :id="state.organization.id"
              class="linklabel-user"
              :value="selectedUser.firstName + ' ' + selectedUser.lastName"
              type="user"
            />
            <LinkLabel
              v-if="state.organization"
              :id="state.organization.id"
              :value="state.organization.code + ' - ' + state.organization.name"
              type="organization"
              :company-code="state.organization.company ? state.organization.company.code : null"
            />
            <!--            <label class="mr-2 text-xl">{{ language }}</label>-->
          </div>
        </div>
      </div>

      <div
        v-for="(subtype, i) in reportTemplateSubTypes"
        class="new-report-form-row"
      >
        <div class="new-report-template-wrapper flex align-items-center mb-2">
          <div class="new-report-form-label w-8">
            {{ subtype.label }}
          </div>
          <Dropdown
            v-model="reportTemplateSubTypes[i].selected"
            class="dropdown w-full"
            :options="subtype.options"
            option-label="alias"
            :placeholder="subtype.placeholder"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ReportService from "@/services/ReportService"
import { reactive, ref, watch } from "vue"
import useVuelidate from "@vuelidate/core"
import { reportTemplateSubTypes as _reportTemplateSubTypes } from "../../../constants/reportTemplateSubTypes"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import { state } from "@/utils/state"

export default {
  name: "TemplateAddModal",
  components: { LinkLabel },
  props: {
    selectedUser: {
      type: Object,
      default: null,
    },
    organization: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
  },
  emits: ["template-added"],
  setup(props, { emit }) {
    const reportService = new ReportService()
    const editorContent = ref("")
    const languages = ["EN", "NL", "FR", "DE"]
    const templateLocale = ref("EN")
    const form = ref({
      alias: "",
      internalAlias: "",
      title: "",
    })
    const rules = {
      alias: {},
      internalAlias: {},
      title: {},
    }

    const v$ = useVuelidate(rules, form)

    const reportTemplateSubTypes = reactive(
      _reportTemplateSubTypes.map((subType) => {
        let subtypeLabel
        switch (subType) {
          case "REPORT_CONTENT": {
            subtypeLabel = "Body"
            break
          }
          case "REPORT_HEADER": {
            subtypeLabel = "Header"
            break
          }
          case "REPORT_FOOTER": {
            subtypeLabel = "Footer"
            break
          }
          case "REPORT_CONTENT_HEADER_LEFT": {
            subtypeLabel = "Contact info"
            break
          }
          case "REPORT_CONTENT_HEADER_RIGHT": {
            subtypeLabel = "Patient info"
            break
          }
          case "REPORT_TERMS_AND_CONDITIONS": {
            subtypeLabel = "Terms and conditions"
          }
        }

        return {
          key: subType,
          label: subtypeLabel,
          options: [],
          placeholder: "Select a template part",
        }
      })
    )

    // watch(
    //   () => templateLocale.value,
    //   () => {
    //     loadTemplateParts()
    //   },
    // )

    loadTemplateParts()

    function loadTemplateParts() {
      Promise.all(
        reportTemplateSubTypes.map((subType) =>
          reportService.getReportTemplatesByType(subType.key, props.language, props.organization)
        )
      ).then((options) => {
        reportTemplateSubTypes.forEach((subType, index) => {
          subType.options = options[index].data.map((option) =>
            Object.assign(option, { value: option.value })
          )
        })
      })
    }

    function addTemplate() {
      const findSelectedOptionId = (option) => {
        const found = reportTemplateSubTypes.find((type) => type.key === option)
        return found.selected ? found.selected.id : found.options[found.options.length - 1].id
      }

      if (props.selectedUser == null) {
        reportService
          .addTemplate(
            form.value.alias,
            form.value.internalAlias,
            form.value.title,
            props.language,
            findSelectedOptionId("REPORT_CONTENT"),
            findSelectedOptionId("REPORT_CONTENT_HEADER_LEFT"),
            findSelectedOptionId("REPORT_CONTENT_HEADER_RIGHT"),
            findSelectedOptionId("REPORT_HEADER"),
            findSelectedOptionId("REPORT_FOOTER"),
            findSelectedOptionId("REPORT_TERMS_AND_CONDITIONS"),
            props.organization
          )
          .then(() => {
            emit("template-added")
          })
      } else {
        reportService
          .addTemplateForOrganizationUser(
            form.value.alias,
            form.value.internalAlias,
            form.value.title,
            props.language,
            findSelectedOptionId("REPORT_CONTENT"),
            findSelectedOptionId("REPORT_CONTENT_HEADER_LEFT"),
            findSelectedOptionId("REPORT_CONTENT_HEADER_RIGHT"),
            findSelectedOptionId("REPORT_HEADER"),
            findSelectedOptionId("REPORT_FOOTER"),
            findSelectedOptionId("REPORT_TERMS_AND_CONDITIONS"),
            props.organization,
            props.selectedUser.id
          )
          .then(() => {
            emit("template-added")
          })
      }
    }

    return {
      languages,
      templateLocale,
      reportTemplateSubTypes,
      editorContent,
      v$,
      addTemplate,
      state,
    }
  },
}
</script>

<style scoped></style>
