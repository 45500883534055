
import { reportTemplateSubTypes as _reportTemplateSubTypes } from "../../../constants/reportTemplateSubTypes"
import { reactive, ref, defineComponent } from "vue"
import ReportService from "@/services/ReportService"
import useVuelidate from "@vuelidate/core"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default defineComponent({
  name: "TemplateEditModal",
  emits: ["template-changed"],
  props: {
    template: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const reportService = new ReportService()
    const form = ref({
      alias: "",
      internalAlias: "",
      title: "",
    })
    const rules = {
      alias: {},
      internalAlias: {},
      title: {},
    }
    const v$ = useVuelidate(rules, form)
    const reportTemplateSubTypes = reactive(
      _reportTemplateSubTypes.map((subType) => {
        let subtypeLabel
        switch (subType) {
          case "REPORT_CONTENT": {
            subtypeLabel = "Body"
            break
          }
          case "REPORT_HEADER": {
            subtypeLabel = "Header"
            break
          }
          case "REPORT_FOOTER": {
            subtypeLabel = "Footer"
            break
          }
          case "REPORT_CONTENT_HEADER_LEFT": {
            subtypeLabel = "Contact info"
            break
          }
          case "REPORT_CONTENT_HEADER_RIGHT": {
            subtypeLabel = "Patient info"
            break
          }
          case "REPORT_TERMS_AND_CONDITIONS": {
            subtypeLabel = "Terms and conditions"
          }
        }
        return {
          key: subType,
          label: subtypeLabel,
          options: [],
          placeholder: "Select a template part",
          selected: { id: String },
        }
      })
    )
    function loadTemplateParts(callback: any) {
      Promise.all(
        reportTemplateSubTypes.map((subType) =>
          reportService.getReportTemplatesByType(
            subType.key,
            props.template.language,
            props.organization.id
          )
        )
      ).then((options) => {
        reportTemplateSubTypes.forEach((subType, index) => {
          subType.options = options[index].data.map((option: any) =>
            Object.assign(option, { value: option.value })
          )
        })
        callback && callback()
      })
    }
    loadOptions()
    function loadOptions() {
      form.value.alias = props.template.alias
      form.value.internalAlias = props.template.internalAlias
      form.value.title = props.template.title
      reportService.getReportTemplate(props.template.id).then((templateDetails) => {
        loadTemplateParts(() => {
          //pick correct options
          reportTemplateSubTypes.forEach((subType) => {
            subType.options.forEach((option) => {
              //just take first type from options
              const key = subType.key
                .split("_")
                .map((str) => str.toLowerCase())
                .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                .filter((type, index) => index !== 0)
                .join("")
                .split("")
                .map((char, index) => (index === 0 ? char.toLowerCase() : char))
                .join("")
              if (templateDetails.data[key] && option.id === templateDetails.data[key].id) {
                Object.assign(subType.selected, option)
              }
            })
          })
        })
      })
    }
    function saveChanges() {
      const findSelectedOptionId = (option: any) => {
        const found = reportTemplateSubTypes.find((type) => type.key === option)
        return found.selected ? found.selected.id : found.options[found.options.length - 1].id
      }
      reportService
        .updateReportTemplate(
          props.template.id,
          form.value.alias,
          form.value.internalAlias,
          form.value.title,
          props.template.language,
          findSelectedOptionId("REPORT_CONTENT"),
          findSelectedOptionId("REPORT_CONTENT_HEADER_LEFT"),
          findSelectedOptionId("REPORT_CONTENT_HEADER_RIGHT"),
          findSelectedOptionId("REPORT_HEADER"),
          findSelectedOptionId("REPORT_FOOTER"),
          findSelectedOptionId("REPORT_TERMS_AND_CONDITIONS")
        )
        .then(() => {
          emit("template-changed")
        })
    }
    return {
      reportTemplateSubTypes,
      saveChanges,
      v$,
    }
  },
  components: { PrimaryButton },
})
