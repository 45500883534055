
import { ref, defineComponent } from "vue"
import TemplateEditModal from "@/components/reports/tools/TemplateEditModal.vue"
import { useToast } from "primevue/usetoast"
import { useConfirm } from "primevue/useconfirm"
import ReportService from "@/services/ReportService"

import LinkLabel from "../navigation/LinkLabel.vue"

export default defineComponent({
  name: "TemplateItem",
  components: { TemplateEditModal, LinkLabel },

  props: {
    template: {
      type: Object,
      default: null,
    },
    organization: {
      type: String,
      default: null,
    },
  },
  emits: ["template-changed"],
  setup(props, { emit }) {
    const showEdit = ref(false)
    let templateRef = ref()
    const toast = useToast()
    const confirm = useConfirm()
    const reportService = new ReportService()

    function deleteTemplate(event: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to delete this template? There is no way back",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          reportService.deleteReportTemplate(props.template.id).then(() => {
            templateChanged()
            toast.add({
              severity: "success",
              summary: "Successfully deleted template",
              life: 3000,
            })
          })
        },
      })
    }

    function templateChanged() {
      showEdit.value = false
      emit("template-changed")
    }

    return {
      showEdit,
      templateChanged,
      templateRef,
      deleteTemplate,
    }
  },
})
